import Token from './Token';

class XolabotXWM {
    /**
     * List of listeners, grouped by event name.
     * Asterisk can be used to listen for all events.
     * @private
     */
    listeners = { '*': [] };

    /**
     * Create a new instance.
     * @param {string} origin  Origin to communicate with.
     * @param {string} channel Channel for this instance. Should be unique for each instance.
     * @param {Window} window  Target window.
     */
    constructor(origin, channel = this.createChannel(), window = null) {
        this.origin = this.getOrigin(origin);
        this.channel = channel;
        this.window = window;
        global.window.addEventListener('message', this.handleWindowMessage, false);
    }

    /**
     * Create new instance using token.
     * @param {String}      token
     * @return {XolabotXWM}
     */
    static fromToken(token) {
        const { channel, origin } = Token.decode(token);
        return new this(origin, channel, window.opener || (window.parent !== window ? window.parent : null));
    }

    /**
     * Default message handler on global window object.
     * Used for parsing payload, checking origin and channel.
     * Parsed message will be dispatched to all the listeners.
     * @private
     */
    handleWindowMessage = ({ origin, data }) => {
        if (origin === this.origin) {
            try {
                const { channel, event, payload } = JSON.parse(data);
                if (channel === this.channel) {
                    this.dispatch(event, payload);
                }
            } catch (e) {
                // Ignore JSON parse errors since this message is probably not meant for us.
            }
        }
    };

    /**
     * Attach to the given window.
     * Used mostly for sending messages.
     * @param {Window} window
     */
    attach(window) {
        this.window = window;
    }

    /**
     * Create a token for communicating with this instance from another window.
     */
    createToken() {
        const payload = { origin: window.origin, channel: this.channel };
        return Token.encode(payload);
    }

    /**
     * Get the origin from the given URL.
     * @param {string} url
     * @private
     */
    getOrigin(url) {
        const a = document.createElement('a');
        a.href = url;
        return a.origin;
    }

    /**
     * Send a message to the attached window.
     * @param {string} event
     * @param {any} payload
     */
    send(event, payload) {
        if (this.window) {
            const message = { channel: this.channel, event, payload };
            return this.window.postMessage(JSON.stringify(message), this.origin);
        }
    }

    /**
     * Subscribe to the event.
     * @param {string}    event
     * @param {Function}  callback
     * @return {Function} Function for unsubscribing.
     */
    on(event, callback) {
        if (this.listeners[event]) {
            this.listeners[event].push(callback);
        } else {
            this.listeners[event] = [callback];
        }

        // Calling this function will unsubscribe the listener from future events.
        return () => this.off(event, callback);
    }

    /**
     * Subscribe to all events.
     * @param {Function}  callback
     * @return {Function} Function for unsubscribing.
     */
    all(callback) {
        this.listeners['*'].push(callback);
        return () => this.off('*', callback);
    }

    /**
     * Unsubscribe from an event.
     * @param {string}   event
     * @param {Function} callback
     */
    off(event, callback) {
        const listeners = this.listeners[event];
        listeners.splice(listeners.indexOf(callback), 1);
    }

    /**
     * Dispatch an event to all the listeners.
     * @param {string} event
     * @param {any} payload
     * @private
     */
    dispatch(event, payload) {
        (this.listeners[event] || []).forEach(listener => {
            listener(payload, event);
        });
        this.listeners['*'].forEach(listener => {
            listener(payload, event);
        });
    }

    /**
     * Create a random string for the channel.
     * @param {number} length
     * @private
     */
    createChannel(length = 16) {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let random = '';
        for (let i = 0; i < length; i++) {
            random += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return random;
    }
}

export default XolabotXWM;
