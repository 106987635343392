class Token {
    /**
     * Create a URL-safe token from the given input.
     * @param {any} input
     */
    static encode(input) {
        return btoa(JSON.stringify(input))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '~');
    }

    /**
     * Decode given token and return default value if decoding fails.
     * @param {string} input
     * @param {any} defaultValue
     */
    static decode(input, defaultValue = {}) {
        try {
            return JSON.parse(
                atob(
                    input
                        .replace(/-/g, '+')
                        .replace(/_/g, '/')
                        .replace(/~/g, '='),
                ),
            );
        } catch (e) {
            return defaultValue;
        }
    }
}

export default Token;
